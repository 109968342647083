#section-presentation {
    margin: auto;    
    height: 400px;
    width: 70%;
    display: flex;
    justify-content: space-around;
    scroll-margin-top: 80px; 

}

.presentation-title {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

 h1 {
    font-size: 48px;
    color: #186262;
    text-shadow: rgba(0, 0, 0, 0.4) 1px 4px 4px;
}

.presentation-title h2 {
    font-size: 32px;
    color:#186262;
    margin-left: 22%;
    text-shadow: rgba(0, 0, 0, 0.4) 1px 3px 3px;

}

.presentation-img {
    display: flex;
    align-items: center;
}

.presentation-img img {
    width: 220px;
    border-radius: 100%;
    border: #186262 3px solid;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 6px 6px;

}

.blue-section {
    min-height: 500px;
    padding: 50px 100px;
    background-color: #186262;
    text-align: center;
    scroll-margin-top: 80px; 
}
.blue-section h2 {
    font-size : 36px;
    color:#fff;
    margin-bottom: 50px;
    
}
.blue-section p {
    color:#fff;
   padding: 50px 100px;
   line-height: 2;
}

.white-section {
    min-height: 500px;
    padding: 50px 100px;
    text-align: center;
    scroll-margin-top: 62px; 

}
.white-section h2 {
    font-size : 36px;
    color: #186262;
    margin-bottom: 50px;
    
}


@media screen and (max-width: 1239px) {

    #section-presentation {
        max-height: 300px;
    }

    .presentation-title {
        width: 90%;
    }
    .presentation-title h1 {
        font-size: 36px;
    }
    
    .presentation-title h2 {
        font: 20px bold;
        text-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
    }
    
    .presentation-img img {
        width: 180px;
    }

    .blue-section {
        max-height: 300px;
        padding: 40px 40px;
        background-color: #186262;
        text-align: center;
        scroll-margin-top: 80px; 
    }
    .blue-section h2 {
        font : 26px bold;
        margin-bottom: 30px;
        
    }
    .blue-section p {
        padding: 30px 60px;
       line-height: 2;
    }
    
    .white-section {
        min-height: 500px;
        padding: 30px 80px;
        text-align: center;
        scroll-margin-top: 62px; 
    
    }
    .white-section h2 {
        font : 26px bold;
        color: #186262;
        margin-bottom: 50px;
        
    }
    
}

@media screen and (max-width:699px) {
    #section-presentation {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .presentation-title {
        width: auto;
    }

    .presentation-title h1 {
        font-size: 30px;
        text-align: center;
        line-height: 2;
    }
    .presentation-title h2 {
        line-height: 1.5;
        font-size: 20px;
        margin: auto;
        text-align: center;
    }
    .presentation-img img {
        width: 120px;
        margin-top: 20px;
        margin-bottom: 20px;
    
    }
}