header {
    position:fixed;
    width:100%;
    max-width: 1600px;
    position:block;
    height : 70px;
    z-index: 1000;
    
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #186262;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); /* Pour ajouter un peu de style */

}

.img-container {
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container img {
    width: 60px;
    margin:5% 0 0 0;
    border-radius : 100%;
    border: #095252 solid 1px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;


}
nav {
    width: 40%;
    margin-right: 30px;
}

nav .nav-links {
    display: flex;
    justify-content: space-between;
}

nav .nav-links li {
    list-style-type: none;
    padding: 20px;}

nav a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 500;

}

a:hover {
    text-decoration: underline;
}

.navbar {
    width: auto;
}
.navbar button {
    appearance: none;
}

#burger-menu-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    background-color: none;
    cursor: pointer;
}


div button {
    background-color: transparent;
    border : none;
    
}

.nav-links-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30vw;
    height: 100vh;
    position: fixed;
    top: 68px;
    right: 0;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
}

.nav-links-mobile.open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;

}

.nav-links-mobile li {
    position: relative;
    padding: 50px 0 0 50px;
}
.nav-links-mobile a {  
    color:#095252;
}



@media screen and (max-width:1239px) {
    header {
        position:fixed;
        width: 100%;
        height : 70px;
        z-index: 1000;
        
    }
    
    header .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #186262;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); /* Pour ajouter un peu de style */
    }
}

@media screen and (max-width:699px) {
    .nav-links-mobile {
        width: 100vw;
        align-items: center;
    }

    .nav-links-mobile li {
        padding: 40px 0;
    }
}