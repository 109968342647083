#contact {
    position: relative;
}
#contact-form {
    margin:auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
input,textarea {
  font-size: 16px;
  color: #095252;
  font-family: Lusitana;
}
#contact-form label {
    width: 100%;
    font-size: 30px;
    text-align: left;
    color: #186262;
}

#contact-form input:not(#form-message, #form-submit) {
    margin: 20px 0;
    width: 99%;
    height: 50px;
    padding: 10px;
    font-family: Lusitana;
    background-color:#E3F9F9;
    border: none;
    border-width: 1.5px;
    border-color : #186262;
    border-radius: 15px;
    border-style: solid;
    box-sizing: border-box;
    color: #095252;
    font-family: Lusitana;
}

#form-message  {
    height: 300px;
    width: 99%;
    margin: 20px 0;
    padding: 10px;
    letter-spacing: 1px;
    background-color:#E3F9F9;
    font-family: Lusitana;
    border: none;
    border-width: 1.5px;
    border-color : #186262;
    border-radius: 15px;
    border-style: solid;
    box-sizing: border-box;
}

#form-submit {
    margin-top: 40px;
    height: 60px;
    width: 200px;
    border-radius: 25px;
    background-color: #095252;
    color: #ffff;
    font-family: 'Lusitana';
    font-size: 26px;
    border-style: solid;
    border-color: #095252;
    cursor: pointer;
    
}

.message-issue-container {
    position: absolute;
    top: 30%;
    right: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    background-color: #186262;
    border-radius: 30px;
    border: solid 3px #095252;
    width: 30%;
    height: 20%;
    z-index: 1000;
    box-shadow:  0 5px 5px rgba(0, 0, 0, 0.4);
}

@keyframes fadeIn {
    from {
        opacity:0 ;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

#smiley {
    width: 30px;
}

.message-issue {
    color: #E3F9F9;
    font-size: 20px;
    
}
.message-issue-container button {
    cursor: pointer;
    background-color: #E3F9F9;
    color:#095252;
    width: 100px;
    height: 50px;
    border-radius: 15px;
    border : solid 1px #095252;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}


@media screen and (max-width: 1239px) {

    
    #contact-form label {
        width: 89%;
        font-size: 20px;
    }
    
    #contact-form input:not(#form-message, #form-submit) {
        width: 90%;
        padding: 10px;

    }
    
    #form-message  {
        width: 90%;
        height: 250px;
        padding: 10px;
    }
    
    #form-submit {
        margin-top: 20px;
        height: 50px;
        width: 150px;
        font-family: 'Lusitana';
        font-size: 20px;
        
    }

    .message-issue-container {
        top: 30%;
        right: 30%;
        width: 40%;
        height: 20%;
    }
    
}

@media screen and (max-width:699px) {
    #contact-form label {
        width: 98%;
        font-size: 20px;
    }
    
    #contact-form input:not(#form-message, #form-submit) {
        width: 100%;
        padding: 10px;
    }

    #form-message  {
        width: 100%;
        height: 250px;
        padding: 10px;
    }

    .message-issue-container {
        top: 30%;
        right: 25%;
        width: 50%;
        height: 25%;
    }
    .message-issue {
        font-size: 16px; 
    }
    .message-issue-container button {
        width: 80px;
        height: 40px;
    }
}