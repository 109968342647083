footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height : 200px;
    background-color: #186262;
    text-align: center;
    color: white;
}

footer img {
    
    width: 50px;
}

footer p {
    color: white;
}