.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    border-radius: 24px;

    background-color: red;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    border-radius: 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;

    background-color: #2E9191;

}

.slider::before {
    position: absolute;
    content: '';
    width: 26px;
    height: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 24px;

}

input:checked + .slider {
    background-color: #095252;
}

input:checked + .slider::before {
    transform: translate(26px);

}