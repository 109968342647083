

#project-container {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
}

#cross-modal {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 40px;
    top: 80px;
    background-color: white;
    background-image: url('../../public/assets/icons/close.png');
    background-size: cover;
    border: none;
}

#project-container button:hover {
    cursor: pointer;
}

#project-container h2, h3, h4{
   color: #095252;
}
#project-container h2 {
    margin-top: 100px;
   font-size: 70px;
}

#project-container h3 {
    font-size: 26px;
}
#project-container h4 {
    margin-top: 20px;
    font-size: 18px;
    text-decoration: underline;
}

#project-container .item, p {
    color: #095252;
    line-height: 2;
    padding-left: 5px;
}
#project-container .item {
   list-style:circle;
   margin-left: 20px;
}

#project-container .image-container {
    display: flex;
    justify-content: center;
}
#project-image {
    width: 100%;
    padding: 20px;
    max-height: 700px;
    margin-top: 80px;
    border: solid #186262 2px;
    border-radius: 20px;
}

.project-description {
    width: 90%;
    padding: 30px 50px;
    background-color:#E3F9F9;
}

.github-container {
    position: absolute;
    left: 0;
    right: 0 ;
    width: calc(100% - 10%);
    height: 40px;
}

#github-icon {
    position: absolute;
    right: 0px;
    width: 40px;
    cursor: pointer;
    animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}



@media screen and (max-width:1239px) {

  
   

    #project-container h2 {
        font-size: 50px;
    }
    
    #project-container h3 {
        font-size: 20px;
    }
    #project-container h4 {
        font-size: 14px;
    }
    .github-container {
        height: 30px;
    }
    
    #github-icon {
        width: 30px;
    }
}

@media screen and (max-width:699px) {

  
    #cross-modal {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 20px;
    }
    #cross-modal {
        top:50px;
    }

    #close-modal {
        font-size:16px;
    }
    #project-container {
        padding-top: 30px;
    }
    .github-container {
        width:calc(100% - 15%);
    } 

    #project-container .item, p {
        font-size: 12px;
    }
}