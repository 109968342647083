#about {
    position: relative;
    min-height: 400px;
}

#about h2 {
    position: relative;
}

#about p {
    font-size: 20px;
    margin-bottom: 50px;
}

#about::before, #about::after {
    content: '';
    position: absolute;
}


#about::before {
    top: 140px;
    right: 100px;
    width: 200px;
    height: 200px;
    border-top : solid 2px white;
    border-right: solid 2px white;

}

#about::after {
    bottom: 80px;
    left: 100px;
    width: 200px;
    height: 200px;
    border-bottom : solid 2px white;
    border-left: solid 2px white;
}

#about a {
    text-decoration: underline;
    color: #fff;
}
#about a:hover {
    text-decoration: none;
}

@media screen and (max-width:1239px) {

#about p {
    font-size: 14px;
    margin-bottom: 50px;
}

#about::before, #about::after {
    content: '';
    position: absolute;
}


#about::before {
    top: 100px;
    right: 50px;
    width: 100px;
    height: 100px;
}

#about::after {
    bottom: 80px;
    left: 50px;
    width: 100px;
    height: 100px;
}

}

@media screen and (max-width:699px) {
    #about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        row-gap: 20px;
    }

    #about p {
        padding: 0 20px;
    }

    #about::before {
        visibility: hidden;
    }
    #about::after {
        visibility: hidden;
    }
    

}