@import url('https://fonts.googleapis.com/css2?family=Lusitana:wght@400;700&display=swap');

/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font: inherit;
    font-family: Lusitana, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    vertical-align: baseline;
    list-style: none;
}

body {
    max-width: 1600px;
    margin: auto;
}

main {
    padding-top: 70px;
    margin: auto;
}


@media screen and(max-width:1239px){
    body {
        width: 100%;
        max-width: 1200px;
    }
    
    main {
        padding-top: 70px;
        width: 100%;
        max-width: 1200px;
    }
    
}